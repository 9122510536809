<template>
    <div class="al-content">
        <div class="content-top">
            <div class="content-top clearfix">
                <h1 class="al-title ">Group</h1>
            </div>
        </div>
        
        <div v-show="!formShow">
            <div class="button-wrapper" v-show="checkAcl('Add')">
                <button type="button" class="btn btn-default float-left mb-3" @click="add"><i class="ion-plus"></i> Add</button>
            </div>
            <table class="table table-hover table-bordered" id="datatable">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Users</th>
                        <th style="width:150px" v-show="checkAcl('Edit')||checkAcl('Delete')"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in groups" :key="item.id">
                        <td>{{item.Id}}</td>
                        <td>{{item.Name}}</td>
                        <td>{{item.Total}}</td>
                        <td v-show="checkAcl('Edit')||checkAcl('Delete')">
                            <span class="editable-buttons">
                                <button type="button" class="btn btn-default btn-with-icon btn-sm" @click="edit(item)" title="Edit" v-show="checkAcl('Edit')">
                                    <i class="ion-edit p-1"></i>
                                </button>
                                <button type="button" class="btn btn-danger btn-with-icon btn-sm ml-3" @click="remove(item.Id,item.Name)" title="Remove" v-show="checkAcl('Delete')">
                                    <i class="ion-trash-b p-1"></i>
                                </button>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>

        <div v-show="formShow">
            <div class="panel panel-blur with-scroll animated zoomIn" style="max-width:100%">
                <div class="panel-heading clearfix">
                    <h3 class="panel-title">{{formTitle}}</h3>
                </div>
                <div class="panel-body" >
                    
                    <div >
                        <form class="form-horizontal">
                            <div class="form-group row">
                                <label for="Name" class="col-sm-2 control-label">Group Name</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control mb-1" id="groupname" @keyup="nameKeyup" />
                                    <i class="ion-alert-circled form-control-feedback"></i>
                                    <span class="help-block red-text basic-block"></span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="Role" class="col-sm-2 control-label">Permission</label>
                                <div class="col-sm-10">
                                    <div class="row">

                                        <!--=======================================================
    ================ HOME PERMISSION ======================
    =======================================================-->
                                        <div class="col-md-4 col-sm-4">
                                            <div>Home</div>
                                            <div class="jstree jstree-10 jstree-default" tabindex="0">
                                                <ul class="jstree-container-ul jstree-children">
                                                    <li class="jstree-node  jstree-closed">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-ios-home-outline jstree-themeicon-custom"></i>Dashboard
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Home/DashboardGuest">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-contacts jstree-themeicon-custom"></i>Guest
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Home/DashboardGroupSales">
                                                                    <i class="jstree-icon jstree-themeicon ion-arrow-graph-up-right jstree-themeicon-custom"></i>GroupSales
                                                                </a>
                                                            </li>
                                                            
                                                        </ul>
                                                    </li>


                                                </ul>
                                            </div>
                                        </div>

                                        <!--=======================================================
    ================ PRODUCT PERMISSION ======================
    =======================================================-->
                                        <div class="col-md-4 col-sm-4">
                                            <div>Product</div>
                                            <div class="jstree jstree-10 jstree-default" tabindex="0">
                                                <ul class="jstree-container-ul jstree-children">



                                                    <li class="jstree-node  jstree-closed">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-cube jstree-themeicon-custom"></i>Product
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Product/View">
                                                                    <i class="jstree-icon jstree-themeicon ion-navicon-round jstree-themeicon-custom"></i>View Product
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Product/EditPeak">
                                                                    <i class="jstree-icon jstree-themeicon ion-arrow-up-c jstree-themeicon-custom"></i>Edit Peak
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Product/ViewHistory">
                                                                    <i class="jstree-icon jstree-themeicon ion-navicon-round jstree-themeicon-custom"></i>View History
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Product/ViewChart">
                                                                    <i class="jstree-icon jstree-themeicon ion-stats-bars jstree-themeicon-custom"></i>View Chart
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Product/PDFGenerate">
                                                                    <i class="jstree-icon jstree-themeicon ion-document-text jstree-themeicon-custom"></i>PDF Generate
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>


                                                    <li class="jstree-node  jstree-closed ">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-android-apps jstree-themeicon-custom"></i>PeakMonth
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="PeakMonth/List">
                                                                    <i class="jstree-icon jstree-themeicon ion-navicon-round jstree-themeicon-custom"></i>View
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="PeakMonth/Edit">
                                                                    <i class="jstree-icon jstree-themeicon ion-edit jstree-themeicon-custom"></i>Edit
                                                                </a>
                                                            </li>

                                                        </ul>
                                                    </li>


                                                    <li class="jstree-node  jstree-closed jstree-last">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-android-upload jstree-themeicon-custom"></i>File Inventory
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Inventory/Upload">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-upload jstree-themeicon-custom"></i>Upload
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Inventory/ViewFile">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-search jstree-themeicon-custom"></i>ViewFile
                                                                </a>
                                                            </li>

                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Inventory/EditAll">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-people jstree-themeicon-custom"></i>Edit All Other Upload
                                                                </a>
                                                            </li>

                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <!--=======================================================
    ================ SALE PERMISSION ======================
    =======================================================-->

                                        <div class="col-md-4 col-sm-4">
                                            <div>Sales</div>
                                            <div class="jstree jstree-10 jstree-default" tabindex="0">
                                                <ul class="jstree-container-ul jstree-children">


                                                    <li class="jstree-node  jstree-closed jstree-last">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-android-upload jstree-themeicon-custom"></i>Revenue
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Revenue/Upload">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-upload jstree-themeicon-custom"></i>Upload
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Revenue/ViewFile">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-search jstree-themeicon-custom"></i>View File
                                                                </a>
                                                            </li>

                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Revenue/ViewAll">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-people jstree-themeicon-custom"></i>View All Other Upload
                                                                </a>
                                                            </li>

                                                        </ul>
                                                    </li>


                                                    <li class="jstree-node  jstree-closed ">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-android-clipboard jstree-themeicon-custom"></i>Import Oder
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="ImportOrder/List">
                                                                    <i class="jstree-icon jstree-themeicon ion-navicon-round jstree-themeicon-custom"></i>List
                                                                </a>
                                                            </li>

                                                            <li class="jstree-node  jstree-leaf jstree-leaf ">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="ImportOrder/Edit">
                                                                    <i class="jstree-icon jstree-themeicon ion-edit jstree-themeicon-custom"></i>Edit
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf ">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="ImportOrder/Remove">
                                                                    <i class="jstree-icon jstree-themeicon ion-trash-b jstree-themeicon-custom"></i>Remove
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf ">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="ImportOrder/ViewAll">
                                                                    <i class="jstree-icon jstree-themeicon ion-edit jstree-themeicon-custom"></i>ViewAll
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="ImportOrder/ExportExcel">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-download jstree-themeicon-custom"></i>Export Excel
                                                                </a>
                                                            </li>

                                                        </ul>
                                                    </li>



                                                    <li class="jstree-node  jstree-closed ">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-android-contacts jstree-themeicon-custom"></i>GroupSale
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="GroupSale/List">
                                                                    <i class="jstree-icon jstree-themeicon ion-navicon-round jstree-themeicon-custom"></i>List
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="GroupSale/Edit">
                                                                    <i class="jstree-icon jstree-themeicon ion-edit jstree-themeicon-custom"></i>Edit
                                                                </a>
                                                            </li>

                                                        </ul>
                                                    </li>


                                                    <li class="jstree-node  jstree-closed ">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-arrow-graph-up-right jstree-themeicon-custom"></i>Sales Forecast
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="SalesForecast/View">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-list jstree-themeicon-custom"></i>View
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="SalesForecast/Download">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-download jstree-themeicon-custom"></i>Download
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li class="jstree-node  jstree-closed ">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-ios-list-outline jstree-themeicon-custom"></i>Niên Vụ
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Nienvu/View">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-list jstree-themeicon-custom"></i>View
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Nienvu/Upload">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-download jstree-themeicon-custom"></i>Upload
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li class="jstree-node  jstree-closed ">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-ios-location jstree-themeicon-custom"></i>Sale Zones
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Zones/Edit">
                                                                    <i class="jstree-icon jstree-themeicon ion-edit jstree-themeicon-custom"></i>Edit
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>

                                        <!--=======================================================
    ================ Feedback ======================
    =======================================================-->

                                        <div class="col-md-4 col-sm-4">
                                            <div>External Pages</div>
                                            <div class="jstree jstree-10 jstree-default" tabindex="0">
                                                <ul class="jstree-container-ul jstree-children">
                                                    <li id="n1" class="jstree-node  jstree-closed jstree-last">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-android-people jstree-themeicon-custom"></i>Feedback
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li id="n5" class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Feedback/View">
                                                                    <i class="jstree-icon jstree-themeicon ion-navicon-round jstree-themeicon-custom"></i>ViewFeedback
                                                                </a>
                                                            </li>
                                                            <li id="n7" class="jstree-node  jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Feedback/Enter">
                                                                    <i class="jstree-icon jstree-themeicon ion-edit jstree-themeicon-custom"></i>EnterFeedback
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>


                                                </ul>
                                            </div>
                                        </div>




                                        <!--=======================================================
    ================ RESOURCE FILES PERMISSION ======================
    =======================================================-->

                                        <div class="col-md-4 col-sm-4">
                                            <div>Resource Files</div>
                                            <div class="jstree jstree-10 jstree-default" tabindex="0">
                                                <ul class="jstree-container-ul jstree-children">


                                                    <li class="jstree-node  jstree-closed ">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-social-usd jstree-themeicon-custom"></i>Bảng Giá
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Resource/BangGiaDownload">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-upload jstree-themeicon-custom"></i>Download
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Resource/BangGiaUpload">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-download jstree-themeicon-custom"></i>Upload
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li class="jstree-node  jstree-closed ">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-ios-pint jstree-themeicon-custom"></i>Catalog Rượu
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Resource/CatalogRuouDownload">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-upload jstree-themeicon-custom"></i>Download
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Resource/CatalogRuouUpload">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-download jstree-themeicon-custom"></i>Upload
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li class="jstree-node  jstree-closed ">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-ios-pint jstree-themeicon-custom"></i>Catalog Sake
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Resource/CatalogSakeDownload">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-upload jstree-themeicon-custom"></i>Download
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Resource/CatalogSakeUpload">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-download jstree-themeicon-custom"></i>Upload
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li class="jstree-node  jstree-closed ">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-ios-pricetags jstree-themeicon-custom"></i>Hồ Sơ Năng Lực
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Resource/HosonanglucDownload">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-upload jstree-themeicon-custom"></i>Download
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Resource/HosonanglucUpload">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-download jstree-themeicon-custom"></i>Upload
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li class="jstree-node  jstree-closed ">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-android-walk jstree-themeicon-custom"></i>Training
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Resource/TrainingDownload">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-upload jstree-themeicon-custom"></i>Download
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Resource/TrainingUpload">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-download jstree-themeicon-custom"></i>Upload
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>


                                                    <li class="jstree-node  jstree-closed ">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-ios-wineglass jstree-themeicon-custom"></i>Tasting Note
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Resource/TastingNoteDownload">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-upload jstree-themeicon-custom"></i>Download
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Resource/TastingNoteUpload">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-download jstree-themeicon-custom"></i>Upload
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>


                                                    <li class="jstree-node  jstree-closed ">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-ios-people jstree-themeicon-custom"></i>Marketing Event
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Resource/MarketingEventDownload">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-upload jstree-themeicon-custom"></i>Download
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Resource/MarketingEventUpload">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-download jstree-themeicon-custom"></i>Upload
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>


                                        <!--=======================================================
    ================ Hamper PERMISSION ======================
    =======================================================-->

                                        <div class="col-md-4 col-sm-4">
                                            <div>Hamper</div>
                                            <div class="jstree jstree-10 jstree-default" tabindex="0">
                                                <ul class="jstree-container-ul jstree-children">

                                                    <li class="jstree-node  jstree-closed ">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-ios-pint jstree-themeicon-custom"></i>Hamper Price 2024
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Resource/HamperPrice2024Download">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-upload jstree-themeicon-custom"></i>Download
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Resource/HamperPrice2024Upload">
                                                                    <i class="jstree-icon jstree-themeicon ion-android-download jstree-themeicon-custom"></i>Upload
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li class="jstree-node  jstree-closed ">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-cube jstree-themeicon-custom"></i>Product Type
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="ProductType/View">
                                                                    <i class="jstree-icon jstree-themeicon ion-navicon-round jstree-themeicon-custom"></i>View
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="ProductType/Edit">
                                                                    <i class="jstree-icon jstree-themeicon ion-edit jstree-themeicon-custom"></i>Edit
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li class="jstree-node  jstree-closed ">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-cube jstree-themeicon-custom"></i>Product Images
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="ProductDetail/View">
                                                                    <i class="jstree-icon jstree-themeicon ion-navicon-round jstree-themeicon-custom"></i>View
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="ProductDetail/Edit">
                                                                    <i class="jstree-icon jstree-themeicon ion-edit jstree-themeicon-custom"></i>Edit
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li class="jstree-node  jstree-closed ">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-bowtie jstree-themeicon-custom"></i>Item
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="HamperItem/View">
                                                                    <i class="jstree-icon jstree-themeicon ion-navicon-round jstree-themeicon-custom"></i>View
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="HamperItem/Edit">
                                                                    <i class="jstree-icon jstree-themeicon ion-edit jstree-themeicon-custom"></i>Edit
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="HamperItem/PDFGenerate">
                                                                    <i class="jstree-icon jstree-themeicon ion-document-text jstree-themeicon-custom"></i>PDF Generate
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li class="jstree-node  jstree-closed ">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-social-dropbox-outline jstree-themeicon-custom"></i>Box
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="HamperBox/View">
                                                                    <i class="jstree-icon jstree-themeicon ion-navicon-round jstree-themeicon-custom"></i>View
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="HamperBox/Edit">
                                                                    <i class="jstree-icon jstree-themeicon ion-edit jstree-themeicon-custom"></i>Edit
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="HamperBox/PDFGenerate">
                                                                    <i class="jstree-icon jstree-themeicon ion-document-text jstree-themeicon-custom"></i>PDF Generate
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li class="jstree-node  jstree-closed ">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-ribbon-b jstree-themeicon-custom"></i>Hamper Setup
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Hamper/View">
                                                                    <i class="jstree-icon jstree-themeicon ion-navicon-round jstree-themeicon-custom"></i>View
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Hamper/Edit">
                                                                    <i class="jstree-icon jstree-themeicon ion-edit jstree-themeicon-custom"></i>Edit
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Hamper/DefaultItems">
                                                                    <i class="jstree-icon jstree-themeicon ion-ios-pricetags-outline jstree-themeicon-custom"></i>Default Items
                                                                </a>
                                                            </li>
                                                            
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Hamper/Tracking">
                                                                    <i class="jstree-icon jstree-themeicon ion-ios-navigate-outline jstree-themeicon-custom"></i>Tracking
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>

                                        <!--=======================================================
    ================ SYSTEM PERMISSION ======================
    =======================================================-->

                                        <div class="col-md-4 col-sm-4">
                                            <div>System</div>
                                            <div class="jstree jstree-10 jstree-default" tabindex="0">
                                                <ul class="jstree-container-ul jstree-children">
                                                    <li id="n1" class="jstree-node  jstree-closed">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-android-people jstree-themeicon-custom"></i>UserGroup
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li id="n5" class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="UserGroup/List">
                                                                    <i class="jstree-icon jstree-themeicon ion-navicon-round jstree-themeicon-custom"></i>List
                                                                </a>
                                                            </li>
                                                            <li id="n6" class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="UserGroup/Add">
                                                                    <i class="jstree-icon jstree-themeicon ion-plus-round jstree-themeicon-custom"></i>Add
                                                                </a>
                                                            </li>
                                                            <li id="n7" class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="UserGroup/Edit">
                                                                    <i class="jstree-icon jstree-themeicon ion-edit jstree-themeicon-custom"></i>Edit
                                                                </a>
                                                            </li>

                                                            <li id="n8" class="jstree-node  jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="UserGroup/Delete">
                                                                    <i class="jstree-icon jstree-themeicon ion-trash-b jstree-themeicon-custom"></i>Delete
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li class="jstree-node  jstree-closed">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-ios-person jstree-themeicon-custom"></i>User
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="User/List">
                                                                    <i class="jstree-icon jstree-themeicon ion-navicon-round jstree-themeicon-custom"></i>List
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="User/Add">
                                                                    <i class="jstree-icon jstree-themeicon ion-plus-round jstree-themeicon-custom"></i>Add
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="User/Edit">
                                                                    <i class="jstree-icon jstree-themeicon ion-edit jstree-themeicon-custom"></i>Edit
                                                                </a>
                                                            </li>
                                                            <li id="n7" class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="User/Profile">
                                                                    <i class="jstree-icon jstree-themeicon ion-ios-person-outline jstree-themeicon-custom"></i>Profile
                                                                </a>
                                                            </li>
                                                            <li id="n7" class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor red-text bold-text" href="javascript:;" tabindex="-1" data="User/IsAdmin">
                                                                    <i class="jstree-icon jstree-themeicon ion-ios-person-outline jstree-themeicon-custom"></i>IsAdmin
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="User/IsGuest">
                                                                    <i class="jstree-icon jstree-themeicon ion-ios-navigate-outline jstree-themeicon-custom"></i>IsGuest
                                                                </a>
                                                            </li>
                                                            <li class="jstree-node  jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="User/Delete">
                                                                    <i class="jstree-icon jstree-themeicon ion-trash-b jstree-themeicon-custom"></i>Delete
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li class="jstree-node  jstree-closed">
                                                        <i class="jstree-icon jstree-ocl"></i>
                                                        <a class="jstree-anchor jstree-parent" href="javascript:;" tabindex="-1" role="treeitem" aria-selected="false" aria-level="1" aria-expanded="true" id="n1_anchor">
                                                            <i class="jstree-icon jstree-themeicon ion-ios-person jstree-themeicon-custom"></i>System Config
                                                        </a>
                                                        <ul class="jstree-children">
                                                            <li class="jstree-node  jstree-leaf">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Config/Read">
                                                                    <i class="jstree-icon jstree-themeicon ion-navicon-round jstree-themeicon-custom"></i>Read
                                                                </a>
                                                            </li>

                                                            <li class="jstree-node jstree-leaf jstree-last">
                                                                <i class="jstree-icon jstree-ocl"></i>
                                                                <a class="jstree-anchor" href="javascript:;" tabindex="-1" data="Config/Edit">
                                                                    <i class="jstree-icon jstree-themeicon ion-edit jstree-themeicon-custom"></i>Edit
                                                                </a>
                                                            </li>

                                                        </ul>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>






                                    </div>                                    
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="text-center">
                                    <button class="btn btn-primary me-3" @click.prevent="save">{{btnSaveText}}</button>
                                    <button class="btn btn-danger" @click.prevent="formShow=false">Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .jstree-default .jstree-anchor{
        margin:2px;
    }
    .jstree-clicked {
        background-color: rgba(32, 158, 145, .65) !important;
    }
    .jstree-semifull {
        background-color: rgba(245, 210, 2, .65) !important;
    }
</style>
<script>
    import $ from 'jquery'
    import _ from 'lodash'
    import { mapState,mapActions } from 'vuex'
    export default {
        name: `UserGroup`,
        props: {
           
        },
        data: function () {
            return {
                LayoutModel: {},
                groups: [],
                formShow: false,
                isEdit: false,
                btnSaveText:'Add',
                formTitle: 'New Group',
                groupIdEdit:0,
                validateName: _.debounce(() => {
                    var nameEl = document.getElementById('groupname');
                    
                    this.call({ Action: 'UserGroup/CheckName', Object: { Name: nameEl.value,Id: this.groupIdEdit } }).then((rs) => {
                        nameEl.parentElement.classList.add('has-feedback');
                        nameEl.parentElement.classList.add('has-error');
                        var err = rs.Error;
                        if (err != "") {
                            err = 'Duplicate! Please choose another name.';
                        }
                        this.inputMark('groupname', err);
                        
                        
                    });
                    
                }, 1000),
            }
        },
        computed: mapState({
            isCheckedLogin: state => state.view.isCheckedLogin,
        }),
        methods: {
            ...mapActions({
                'loading': 'view/setLoading',
                'showModal': 'view/showModal',
                'showToast': 'view/showToast',
                'call': 'api/call'
            }),
            checkAcl(action) {
                return this.$store.state.session.acl.includes(this.$options.name + "/" + action);
            },
            checkFullAcl(router) {
                return this.$store.state.session.acl.includes(router);
            },
            start() {
                this.getAll();
            },
            nameKeyup() {
                var nameEl = document.getElementById('groupname');
                if (!nameEl.value || nameEl.value.trim() == "") {
                    this.inputMark('groupname', 'Please input Group name.');
                    this.validateName.cancel();
                } else {
                    nameEl.nextElementSibling.nextElementSibling.innerHTML = 'Checking...';
                    this.validateName();
                }
            },
            add() {                
                this.formShow = true;
                setTimeout(() => {
                    this.formTitle = 'New group';
                    this.btnSaveText = 'Add';
                    this.groupIdEdit = 0;
                    //reset form
                    $('#groupname').val('');
                    this.inputMark('groupname');
                    $('.jstree-clicked').removeClass('jstree-clicked');
                    $('.jstree-semifull').removeClass('jstree-semifull');
                }, 100);              
                
            },
            edit(item) {
                this.formShow = true;//show form
                this.isEdit = true;//set is editing
                this.formTitle = `Group "${item.Name}" edit`;
                this.btnSaveText = 'Save';
                this.groupIdEdit = item.Id;
                
                setTimeout(() => {
                    //reset form
                    $('#groupname').val(item.Name);
                    this.inputMark('groupname');
                    $('.jstree-clicked').removeClass('jstree-clicked');
                    $('.jstree-semifull').removeClass('jstree-semifull');
                    //parse acl to array
                    const acl = JSON.parse(item.Acl, false);
                    if (acl == null || acl.length==0) return;//check null
                    //put jstree-clicked class for leaf node
                    for (let i = 0; i < acl.length; i++) {
                        const aclname = acl[i];
                        $(`.jstree-anchor[data='${aclname}']`).addClass("jstree-clicked");
                    }
                   
                    //put jstree-clicked class for parent element
                    //count item
                    $('.jstree-parent').each((_, paEl) => {
                        this.parentCheckSelect($(paEl.nextElementSibling));
                    });
                }, 100);
            },
            parentCheckSelect($paEl) {
                if (!$paEl || $paEl.length == 0) return;//check null
                try {
                    const itemCount = $paEl.find('.jstree-node').length;
                    const itemSelectedCount = $paEl.find('.jstree-clicked').length;
                    if (itemSelectedCount == 0) {
                        $paEl.prev().removeClass('jstree-clicked');
                        $paEl.prev().removeClass('jstree-semifull');
                    }
                    else if (itemCount == itemSelectedCount) {
                        $paEl.prev().addClass('jstree-clicked');
                        $paEl.prev().removeClass('jstree-semifull');
                    } else {
                        $paEl.prev().addClass('jstree-semifull');
                        $paEl.prev().removeClass('jstree-clicked');
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            async remove(id,name) {
                
                this.loading(true);
                const rs = await this.call({ Action: 'UserGroup/Remove', Object: { Id: id, UserName:name } })
                this.loading(false);                
                if (rs.Error != "") {
                    let error = "";
                    //set error message
                    if (rs.Error == "notfound") error = "Group not found";
                    else if (rs.Error == "notempty") error = "There are Users existed in this group. Please empty this group.";
                    else error = rs.Error;
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: error, type: 'error' });

                } else {
                    _.remove(this.groups, function (n) {
                        return n.Id == id;
                    });
                    this.showToast({ title: '', content: `Remove group "${name}" successfull.`, type: 'success' });
                }
            },
            async save() {
                //validate
                var nameEl = document.getElementById('groupname');
                if (nameEl.nextElementSibling.nextElementSibling.innerHTML.trim().replace('&nbsp;', '') != '') {
                    this.showModal({ isShow: true, title: 'Error', content: nameEl.nextElementSibling.nextElementSibling.innerHTML, type: 'error' });
                    return;
                }
                if (!nameEl.value || nameEl.value.trim() == "") {
                    this.inputMark('groupname', 'Please input Group name.');
                    return;
                }
                //get selected acl
                const acl = [];
                $('.jstree-clicked:not(.jstree-parent)').each((i, item) => {                    
                    acl.push(item.getAttribute("data"));
                });                

                this.loading(true);
                let Action = "UserGroup/Edit";
                if (this.groupIdEdit == 0) Action = "UserGroup/Add";
                const rs = await this.call({ Action: Action, Object: { Id: this.groupIdEdit, Name: nameEl.value, Acl: JSON.stringify(acl) } })
                this.loading(false);
                
                if (rs.Error != "") {
                    if (rs.Error == "duplicate") rs.Error = "Duplicate! Please choose another name.";
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });

                } else {
                    this.formShow = false;
                    this.getAll();
                    this.showToast({ isShow: true, title: '', content: `${this.btnSaveText} group "${nameEl.value}" successfull`, type: 'success' });
                }
            },
            async getAll() {
                this.loading(true);
                const rs = await this.call({ Action: 'UserGroup/GetAll' })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    //no permission                    
                    if (rs.Error.includes("Permission Denied")) {
                        window.history.back();
                    }
                } else {                    
                    this.groups = rs.Data;

                }
            },
            inputMark(elId,msg) {
                var nameEl = document.getElementById(elId);
                msg = msg || '';//default value
                //if success
                if (msg == '') {
                    nameEl.parentElement.classList.remove('has-error');
                    nameEl.parentElement.classList.add('has-success');
                    nameEl.nextElementSibling.classList.remove('ion-alert-circled');
                    nameEl.nextElementSibling.classList.add('ion-checkmark-circled');
                    nameEl.nextElementSibling.nextElementSibling.innerHTML = "&nbsp";
                }
                //if error
                else {
                    nameEl.parentElement.classList.add('has-error');
                    nameEl.parentElement.classList.remove('has-success');
                    nameEl.nextElementSibling.classList.add('ion-alert-circled');
                    nameEl.nextElementSibling.classList.remove('ion-checkmark-circled');
                    nameEl.nextElementSibling.nextElementSibling.innerHTML = msg;
                    nameEl.focus();
                }
            }
        },
        mounted() {
            //if already login then start, 
            //else will watch isCheckedLogin to catch its change
            //to call start function
            if (this.isCheckedLogin) this.start();

            //jstree-leaf click
            $('.jstree-leaf a').on('click', (e) => {
                let el = e.target;
                if (el.tagName != 'A') el = el.parentElement;
                if (el.classList.contains('jstree-clicked')) {
                    el.classList.remove('jstree-clicked');
                    
                }
                else {
                    el.classList.add('jstree-clicked');
                }

                //check select for parent element
                //count item
                const $paEl = $(el.parentElement.parentElement);
                this.parentCheckSelect($paEl);
                //const itemCount = $paEl.find('.jstree-node').length;
                //const itemSelectedCount = $paEl.find('.jstree-clicked').length;
                //if (itemSelectedCount == 0) {
                //    $paEl.prev().removeClass('jstree-clicked');
                //    $paEl.prev().removeClass('jstree-semifull');
                //}
                //else if (itemCount == itemSelectedCount) {
                //    $paEl.prev().addClass('jstree-clicked');
                //    $paEl.prev().removeClass('jstree-semifull');
                //} else {
                //    $paEl.prev().addClass('jstree-semifull');
                //    $paEl.prev().removeClass('jstree-clicked');
                //}

            });
            $('a.jstree-parent').on('click', (e) => {
                let el = e.target;
                if (el.tagName != 'A') el = el.parentElement;
                if (el.classList.contains('jstree-clicked')) {
                    el.classList.remove('jstree-clicked');
                    $(el.nextSibling).find(".jstree-leaf a").removeClass("jstree-clicked");
                }
                else {
                    el.classList.add('jstree-clicked');
                    el.classList.remove('jstree-semifull');
                    $(el.nextSibling).find(".jstree-leaf a").addClass("jstree-clicked");
                }
            });
            //collapse/expand tree icon click
            $('.jstree-ocl').on('click', (e) => {
                let el = e.target.parentElement;
                
                if (el.classList.contains('jstree-open')) {
                    el.classList.remove('jstree-open');
                    el.classList.add('jstree-closed');
                    
                }
                else {
                    el.classList.add('jstree-open');
                    el.classList.remove('jstree-closed');
                }
            });
        },
        watch: {
            //catch status from not login to logged in 
            //to call start function.
            //Apply for reload page issue
            isCheckedLogin(newVal) {
                if (newVal) {
                    this.start();
                }
            }
        }
    };
</script>
